import React from "react";
import styles from "./Style.module.css";
import useTaxfilingForm from "./TaxFilingCreate.hook";
import NetSalary from "./component/TaxField/NetSalary";
import SelfProperty from "./component/TaxField/SelfProperty";
import CapitalGain from "./component/TaxField/CapitalGain";
import Profession from "./component/TaxField/Profession";
import OtherSource from "./component/TaxField/OtherSource";
import Deduction from "./component/deduction/Deduction";
import TaxInfoField from "./component/TaxField/TaxInfoField";
import CustomTextField from "../../../components/FormFields/TextField/TextField.component";
import {
  ButtonBase,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import UpperTaxCard from "./component/UpperTaxCard/UpperTaxCard";

function TaxFilingCreate() {
  const {
    form,
    setForm,
    errorData,
    changeTextData,
    user,
    handleSubmit,
    submitToServer,
  } = useTaxfilingForm({});
  return (
    <div>
      <UpperTaxCard data={user} />
      <div className={styles.plainPaper}>
        <div className={styles.heading}>Basic Information</div>
        <div className={styles.flex}>
          <div className={styles.flex1}>
            <div className={styles.leftInfo}>Assessment Year:</div>
            <div className={styles.des}>{form?.as_year}</div>
          </div>
          <div className={styles.flex1}>
            <div className={styles.leftInfo}>Age:</div>
            <div className={styles.des}>{`${user?.age} yrs` || "-"}</div>
          </div>
        </div>
        <div className={styles.flex} style={{ marginTop: "10px" }}>
          <div className={styles.flex1}>
            <div className={styles.leftInfo}>Tax Payer Category:</div>
            <div className={styles.des}>{form?.payer_category}</div>
          </div>
          <div className={styles.flex1}></div>
        </div>
        <div className={styles.flex}>
          <div className={styles.flex21}>
            <div className={styles.heading2}>Tax Regime</div>
            <RadioGroup
              aria-label="option"
              name="tax_regime"
              value={form?.tax_regime}
              onChange={(e) => changeTextData(e.target.value, "tax_regime")}
              row
              className={styles.radioWrap}
            >
              <FormControlLabel
                value="OLD_TAX_REGIME"
                control={<Radio />}
                label="Old Tax Regime"
              />
              <FormControlLabel
                value="NEW_TAX_REGIME"
                control={<Radio />}
                label="New Tax Regime"
              />
            </RadioGroup>
          </div>
          <div className={styles.flex21}>
            <div className={styles.heading2}>City Type</div>
            <RadioGroup
              aria-label="option"
              name="city_type"
              value={form?.city_type}
              onChange={(e) => changeTextData(e.target.value, "city_type")}
              row
              className={styles.radioWrap}
            >
              <FormControlLabel
                value="METRO"
                control={<Radio />}
                label="Metro"
              />
              <FormControlLabel
                value="NON_METRO"
                control={<Radio />}
                label="Non Metro"
              />
            </RadioGroup>
          </div>
        </div>
      </div>
      <div className={styles.plainPaper}>
        <div className={styles.heading}>
          Details for Income and tax calculation
        </div>

        <NetSalary
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
        />
        <SelfProperty
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
        />
        <CapitalGain
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
        />
        <Profession
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
        />
        <OtherSource
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
        />
        <div className={styles.grossTotal}>
          <div className={styles.heading2}>Gross Total Income</div>
          <div className={styles.totalAmount}>₹ {form?.gross_total_income}</div>
        </div>
      </div>
      <div className={styles.plainPaper}>
        <div className={styles.heading}>Details of Deduction</div>
        <Deduction
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
        />
        <div className={styles.grossTotal}>
          <div className={styles.heading2}>Taxable Income</div>
          <div className={styles.totalAmount}>₹ {form?.taxable_income}</div>
        </div>
      </div>
      <div className={styles.plainPaper}>
        <div className={styles.heading}>Summary</div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>1. Tax at Normal Rates</div>
          <div className={styles.right}>
            <div className={styles.total}>₹ {form?.tax_normal_rates}</div>
          </div>
        </div>
        <TaxInfoField form={form} />
        <div className={styles.mainFlex}>
          <div className={styles.left}>Total Tax before Rebate</div>
          <div className={styles.right}>
            <div className={styles.total}>
              ₹ {form?.total_tax_before_rebate}
            </div>
          </div>
        </div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>Less: Tax Rebate u/s 87A</div>
          <div className={styles.right}>
            <div className={styles.total}>₹ {form?.tax_rebate_us_87a}</div>
          </div>
        </div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>Net Tax after Rebate</div>
          <div className={styles.right}>
            <div className={styles.total}>₹ {form?.net_tax_after_rebate}</div>
          </div>
        </div>{" "}
        <div className={styles.mainFlex}>
          <div className={styles.left}>Add: Surcharge</div>
          <div className={styles.right}>
            <div className={styles.total}>₹ {form?.surcharge}</div>
          </div>
        </div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>Add: Health & Education Cess</div>
          <div className={styles.right}>
            <div className={styles.total}>₹ {form?.health_education_cess}</div>
          </div>
        </div>
        <br />
        <div className={styles.grossTotal}>
          <div className={styles.heading2}>Total Tax on Income</div>
          <div className={styles.totalAmount}>
            ₹ {form?.total_tax_on_income}
          </div>
        </div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>
            TDS + TCS + MAT / AMT credit utilized
          </div>
          <div className={styles.right}>
            <CustomTextField
            style={{background:"#ffffff"}}
              type="number"
              isError={errorData?.tds_tcs_mat_amt_credit}
              errorText={errorData?.tds_tcs_mat_amt_credit}
              // label={"Posted Manpower"}
              value={form?.tds_tcs_mat_amt_credit}
              onTextChange={(text) => {
                changeTextData(text, "tds_tcs_mat_amt_credit");
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ backgroundColor: "#F5F7FB" }}
                  >
                    ₹
                  </InputAdornment>
                ),
              }}
              // onBlur={() => {
              //   onBlurHandler("net_income");
              // }}
            />
          </div>
        </div>
        {/* <div className={styles.mainFlex}>
          <div className={styles.left}>Self-Assessment Tax / Advance Tax</div>
          <div className={styles.right}>
            <CustomTextField
            style={{background:"#ffffff"}}
              type="number"
              isError={errorData?.self_assessment_advance_tax}
              errorText={errorData?.self_assessment_advance_tax}
              // label={"Posted Manpower"}
              value={form?.self_assessment_advance_tax}
              onTextChange={(text) => {
                changeTextData(text, "self_assessment_advance_tax");
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ backgroundColor: "#F5F7FB" }}
                  >
                    ₹
                  </InputAdornment>
                ),
              }}
              // onBlur={() => {
              //   onBlurHandler("net_income");
              // }}
            />
          </div>
        </div> */}
        <div className={styles.grossTotal}>
          <div className={styles.heading2}>
            Balance Tax Payable / Refundable
          </div>
          <div className={styles.totalAmount}>
            ₹ {form?.tax_payable_refundable}
          </div>
        </div>
        <div className={styles.grossTotal}>
          <div className={styles.heading2}>
            Tax applicability based on tenure
            {form?.tenure && `( ${form?.tenure} months )`}
          </div>
          <div className={styles.totalAmount}>
            ₹ {form?.tax_applicable_on_tenure}
          </div>
        </div>
      </div>
      <div className={styles.btnWrap}>
        <div className={styles.btnInner}>
          <ButtonBase
            type={"button"}
            className={styles.createBtn21}
            onClick={() => submitToServer("draft")}
          >
            Save As Draft
          </ButtonBase>
        </div>
        <div className={styles.btnInner}>
          <ButtonBase
            type={"button"}
            className={styles.createBtn}
            onClick={handleSubmit}
          >
            SUBMIT
          </ButtonBase>
        </div>
      </div>
    </div>
  );
}

export default TaxFilingCreate;
